import { mapActions } from "vuex";

export default {
  name: "guide",
  components: {},
  props: [],
  data() {
    return {
      accordion: "",
      accordionChild: "",
    };
  },
  computed: {},
  created() {
    this.setNavigation("nav-guide");
  },
  mounted() {},
  methods: {
    ...mapActions({
      setNavigation: "global/NAVIGATE",
    }),
  },
};
